import { default as annoncesYRw4EnaROXMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/annonces.vue?macro=true";
import { default as gammes6oZsWV92z1Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/articles/gammes.vue?macro=true";
import { default as indexGdzQdTIJXQMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/articles/index.vue?macro=true";
import { default as lotsUlLZbUg6f1Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/articles/lots.vue?macro=true";
import { default as types_articlesYoW4s36QlfMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/articles/types_articles.vue?macro=true";
import { default as articleszewEu1XzfDMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/articles.vue?macro=true";
import { default as gammesTHdkYbOGBLMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/gammes.vue?macro=true";
import { default as indexPaWp3RxBROMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/index.vue?macro=true";
import { default as index6I97X8IDPFMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/modeles/index.vue?macro=true";
import { default as documentsuLGBtVSdfIMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/modeles/versions/[id]/documents.vue?macro=true";
import { default as indexHDCoTjnGPzMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/modeles/versions/[id]/index.vue?macro=true";
import { default as medias4y3kSfypb8Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/modeles/versions/[id]/medias.vue?macro=true";
import { default as _91id_93Zuqb4PVTHCMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/modeles/versions/[id].vue?macro=true";
import { default as modelesdCWLjh12PoMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/modeles.vue?macro=true";
import { default as constructionsg51UsME6fXMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions.vue?macro=true";
import { default as indexv2rLi3AFltMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/index.vue?macro=true";
import { default as indexJH89g2UZmiMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres/[id]/index.vue?macro=true";
import { default as lignes8dLOfRKRFtMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres/[id]/lignes.vue?macro=true";
import { default as optionsVN54iacPRVMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres/[id]/options.vue?macro=true";
import { default as parametresBNW68aKIX2Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres/[id]/parametres.vue?macro=true";
import { default as _91id_93abONIFUqgsMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres/[id].vue?macro=true";
import { default as indexUgIg6cww6iMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres/index.vue?macro=true";
import { default as metresMmcoRMlQrsMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres.vue?macro=true";
import { default as terrains1RY5poULUVMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/terrains.vue?macro=true";
import { default as cataloguesU3I8RapsLwMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues.vue?macro=true";
import { default as _91id_93pLdVyTyi02Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts/clients/[id].vue?macro=true";
import { default as indexnMNOXgWohOMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts/clients/index.vue?macro=true";
import { default as clientsffAvTu3tDeMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts/clients.vue?macro=true";
import { default as indexWzTnUHloBlMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts/index.vue?macro=true";
import { default as indexPTEbTKWPDHMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts/ssttfours/index.vue?macro=true";
import { default as ssttfoursiXfTDayg5mMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts/ssttfours.vue?macro=true";
import { default as contactsjTZy2MWGeiMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts.vue?macro=true";
import { default as dashboardeeIafUpxV9Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/dashboard.vue?macro=true";
import { default as documentsbl8jPvyOfoMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/documents.vue?macro=true";
import { default as commandesOsucVXmCWjMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/achats/commandes.vue?macro=true";
import { default as facturest3A0hRZuUDMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/achats/factures.vue?macro=true";
import { default as indexIYoiwNWZYiMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/achats/index.vue?macro=true";
import { default as reglementsJ9ciXj7VsbMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/achats/reglements.vue?macro=true";
import { default as achatsteYEVZparuMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/achats.vue?macro=true";
import { default as facturation3rkAWMoOWYMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/dashboards/facturation.vue?macro=true";
import { default as indexYdHFTWz0kZMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/dashboards/index.vue?macro=true";
import { default as ventesorSm34X6IlMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/dashboards/ventes.vue?macro=true";
import { default as dashboardsaqW0zROQ6EMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/dashboards.vue?macro=true";
import { default as exportsMzcDM1G7FeMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/exports.vue?macro=true";
import { default as indexlJbHF19hoyMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/index.vue?macro=true";
import { default as indexxSLqUiuAvYMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/ventes/index.vue?macro=true";
import { default as suivifactufb6xfEc35EMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/ventes/suivifactu.vue?macro=true";
import { default as ventesJW1mSpcbjoMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/ventes.vue?macro=true";
import { default as gesfiRrS66Rb1s9Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi.vue?macro=true";
import { default as indexHBBtryseX5Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/index.vue?macro=true";
import { default as metresVz2yci8Pb0Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/metres.vue?macro=true";
import { default as annonces8OYE5Y22axMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/diffusion/annonces.vue?macro=true";
import { default as ubiflowlRtttXZHdAMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/diffusion/ubiflow.vue?macro=true";
import { default as diffusionsEAtXwjut0Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/diffusion.vue?macro=true";
import { default as indexChLB7qeoTfMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/documents/index.vue?macro=true";
import { default as typeskX8OdhOVR3Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/documents/types.vue?macro=true";
import { default as documents3INYxLQg7tMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/documents.vue?macro=true";
import { default as generalZMvgHsU0VzMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/editions/general.vue?macro=true";
import { default as index3jctKFuC1aMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/editions/index.vue?macro=true";
import { default as noticesPwu1x04c26Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/editions/notices.vue?macro=true";
import { default as ppcstAH1hNzSZ5YMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/editions/ppcst.vue?macro=true";
import { default as editionsIJ7I43rDeSMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/editions.vue?macro=true";
import { default as affectations4ysgIZt2owMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/financiers/affectations.vue?macro=true";
import { default as banqueYiMhzK0tDJMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/financiers/banque.vue?macro=true";
import { default as facturationr0UCLS57hWMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/financiers/facturation.vue?macro=true";
import { default as indexpbnEcqFP0pMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/financiers/index.vue?macro=true";
import { default as validationsL73EWZdvYeMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/financiers/validations.vue?macro=true";
import { default as financiersjDJSfr0ArEMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/financiers.vue?macro=true";
import { default as garantsz37xcQzSzxMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/garants.vue?macro=true";
import { default as index83bEx22gl7Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/index.vue?macro=true";
import { default as marqueDSrLoQ2tBPMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/marque.vue?macro=true";
import { default as secteursCR9drwd6YzMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/secteurs.vue?macro=true";
import { default as utilisateurssR84EBrpYcMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/utilisateurs.vue?macro=true";
import { default as ventePWsZL5fhWKMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/vente.vue?macro=true";
import { default as parametresY9qz06ulSoMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres.vue?macro=true";
import { default as indexi6lTsLMxs3Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/avp/index.vue?macro=true";
import { default as lignesgkehLgUXdAMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/avp/metre/[mid]/lignes.vue?macro=true";
import { default as optionsViHjV6eoKRMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/avp/metre/[mid]/options.vue?macro=true";
import { default as _91mid_93ibLayAGHrGMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/avp/metre/[mid].vue?macro=true";
import { default as avpQ3AKBemg4nMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/avp.vue?macro=true";
import { default as analyticsYp9qdR9BhXMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/contrat/analytics.vue?macro=true";
import { default as avenantsHU6GtehhuZMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/contrat/avenants.vue?macro=true";
import { default as facturationWF2TBNAKYgMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/contrat/facturation.vue?macro=true";
import { default as indexVrQuxMTbWIMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/contrat/index.vue?macro=true";
import { default as contrateOMbLZ3w2QMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/contrat.vue?macro=true";
import { default as indexIs2frD66hXMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/index.vue?macro=true";
import { default as cieIuwhuAS5AMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/ci.vue?macro=true";
import { default as epe4unimOJoLsMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/epe.vue?macro=true";
import { default as financementysDOWDnxPYMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/financement.vue?macro=true";
import { default as garantiesX6xHHmGbIrMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/garanties.vue?macro=true";
import { default as pcZkEG69MPrZMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/pc.vue?macro=true";
import { default as prepaschantiersx8vqEa4QFhMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/prepaschantiers.vue?macro=true";
import { default as terrainZfYy3UXDpZMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/terrain.vue?macro=true";
import { default as ppIQeObynHeRMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp.vue?macro=true";
import { default as indexcpJCKyVXneMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/cmd/[cid]/index.vue?macro=true";
import { default as _91cid_93QHObVpk2jgMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/cmd/[cid].vue?macro=true";
import { default as indexNfBgoKpEAnMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/cmd/index.vue?macro=true";
import { default as cmdfT6e2R2UCOMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/cmd.vue?macro=true";
import { default as documentsF6sm9Bn1XaMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/documents.vue?macro=true";
import { default as indexxmqRz3vMd5Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/index.vue?macro=true";
import { default as planification4yjSZVlxH1Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/planification.vue?macro=true";
import { default as travauxdr4pp5JdbAMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux.vue?macro=true";
import { default as _91id_93subc7v0n4XMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id].vue?macro=true";
import { default as indexDLI0ERxKwAMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/index.vue?macro=true";
import { default as projetsBfKIsTdeb8Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets.vue?macro=true";
import { default as indexEj22u0Z1xuMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/index.vue?macro=true";
import { default as commandesgmgcTnNgiTMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/mt/[id]/commandes.vue?macro=true";
import { default as index7oRrsnjU1aMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/mt/[id]/index.vue?macro=true";
import { default as marches15YdozUEEuMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/mt/[id]/marches.vue?macro=true";
import { default as _91id_93dUNhgKkh1zMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/mt/[id].vue?macro=true";
import { default as indexN6ykuvwekbMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/mt/index.vue?macro=true";
import { default as mtS4A8pwuvaWMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/mt.vue?macro=true";
import { default as suiviWsrjySnXh7Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/suivi.vue?macro=true";
import { default as travauxeEL6touxVfMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux.vue?macro=true";
import { default as _91workspace_93vZqV3gncuYMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace].vue?macro=true";
import { default as forgot_45passwordHLJDhTRpVzMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/forgot-password.vue?macro=true";
import { default as index8rmyUL6wd0Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/index.vue?macro=true";
import { default as loginvtD6lzSst8Meta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/login.vue?macro=true";
import { default as reset_45passwordxjuZvla6GJMeta } from "/home/cleavr/app.briik.fr/releases/20241213095323233/pages/reset-password.vue?macro=true";
export default [
  {
    name: _91workspace_93vZqV3gncuYMeta?.name,
    path: "/:workspace()",
    meta: _91workspace_93vZqV3gncuYMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace].vue"),
    children: [
  {
    name: cataloguesU3I8RapsLwMeta?.name,
    path: "catalogues",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues.vue"),
    children: [
  {
    name: "workspace-catalogues-annonces",
    path: "annonces",
    meta: annoncesYRw4EnaROXMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/annonces.vue")
  },
  {
    name: articleszewEu1XzfDMeta?.name,
    path: "articles",
    meta: articleszewEu1XzfDMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/articles.vue"),
    children: [
  {
    name: "workspace-catalogues-articles-gammes",
    path: "gammes",
    meta: gammes6oZsWV92z1Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/articles/gammes.vue")
  },
  {
    name: "workspace-catalogues-articles",
    path: "",
    meta: indexGdzQdTIJXQMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/articles/index.vue")
  },
  {
    name: "workspace-catalogues-articles-lots",
    path: "lots",
    meta: lotsUlLZbUg6f1Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/articles/lots.vue")
  },
  {
    name: "workspace-catalogues-articles-types_articles",
    path: "types_articles",
    meta: types_articlesYoW4s36QlfMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/articles/types_articles.vue")
  }
]
  },
  {
    name: constructionsg51UsME6fXMeta?.name,
    path: "constructions",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions.vue"),
    children: [
  {
    name: "workspace-catalogues-constructions-gammes",
    path: "gammes",
    meta: gammesTHdkYbOGBLMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/gammes.vue")
  },
  {
    name: "workspace-catalogues-constructions",
    path: "",
    meta: indexPaWp3RxBROMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/index.vue")
  },
  {
    name: modelesdCWLjh12PoMeta?.name,
    path: "modeles",
    meta: modelesdCWLjh12PoMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/modeles.vue"),
    children: [
  {
    name: "workspace-catalogues-constructions-modeles",
    path: "",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/modeles/index.vue")
  },
  {
    name: _91id_93Zuqb4PVTHCMeta?.name,
    path: "versions/:id()",
    meta: _91id_93Zuqb4PVTHCMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/modeles/versions/[id].vue"),
    children: [
  {
    name: "workspace-catalogues-constructions-modeles-versions-id-documents",
    path: "documents",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/modeles/versions/[id]/documents.vue")
  },
  {
    name: "workspace-catalogues-constructions-modeles-versions-id",
    path: "",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/modeles/versions/[id]/index.vue")
  },
  {
    name: "workspace-catalogues-constructions-modeles-versions-id-medias",
    path: "medias",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/constructions/modeles/versions/[id]/medias.vue")
  }
]
  }
]
  }
]
  },
  {
    name: "workspace-catalogues",
    path: "",
    meta: indexv2rLi3AFltMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/index.vue")
  },
  {
    name: metresMmcoRMlQrsMeta?.name,
    path: "metres",
    meta: metresMmcoRMlQrsMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres.vue"),
    children: [
  {
    name: _91id_93abONIFUqgsMeta?.name,
    path: ":id()",
    meta: _91id_93abONIFUqgsMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres/[id].vue"),
    children: [
  {
    name: "workspace-catalogues-metres-id",
    path: "",
    meta: indexJH89g2UZmiMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres/[id]/index.vue")
  },
  {
    name: "workspace-catalogues-metres-id-lignes",
    path: "lignes",
    meta: lignes8dLOfRKRFtMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres/[id]/lignes.vue")
  },
  {
    name: "workspace-catalogues-metres-id-options",
    path: "options",
    meta: optionsVN54iacPRVMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres/[id]/options.vue")
  },
  {
    name: "workspace-catalogues-metres-id-parametres",
    path: "parametres",
    meta: parametresBNW68aKIX2Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres/[id]/parametres.vue")
  }
]
  },
  {
    name: "workspace-catalogues-metres",
    path: "",
    meta: indexUgIg6cww6iMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/metres/index.vue")
  }
]
  },
  {
    name: "workspace-catalogues-terrains",
    path: "terrains",
    meta: terrains1RY5poULUVMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/catalogues/terrains.vue")
  }
]
  },
  {
    name: contactsjTZy2MWGeiMeta?.name,
    path: "contacts",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts.vue"),
    children: [
  {
    name: clientsffAvTu3tDeMeta?.name,
    path: "clients",
    meta: clientsffAvTu3tDeMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts/clients.vue"),
    children: [
  {
    name: "workspace-contacts-clients-id",
    path: ":id()",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts/clients/[id].vue")
  },
  {
    name: "workspace-contacts-clients",
    path: "",
    meta: indexnMNOXgWohOMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts/clients/index.vue")
  }
]
  },
  {
    name: "workspace-contacts",
    path: "",
    meta: indexWzTnUHloBlMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts/index.vue")
  },
  {
    name: ssttfoursiXfTDayg5mMeta?.name,
    path: "ssttfours",
    meta: ssttfoursiXfTDayg5mMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts/ssttfours.vue"),
    children: [
  {
    name: "workspace-contacts-ssttfours",
    path: "",
    meta: indexPTEbTKWPDHMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/contacts/ssttfours/index.vue")
  }
]
  }
]
  },
  {
    name: "workspace-dashboard",
    path: "dashboard",
    meta: dashboardeeIafUpxV9Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/dashboard.vue")
  },
  {
    name: "workspace-documents",
    path: "documents",
    meta: documentsbl8jPvyOfoMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/documents.vue")
  },
  {
    name: gesfiRrS66Rb1s9Meta?.name,
    path: "gesfi",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi.vue"),
    children: [
  {
    name: achatsteYEVZparuMeta?.name,
    path: "achats",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/achats.vue"),
    children: [
  {
    name: "workspace-gesfi-achats-commandes",
    path: "commandes",
    meta: commandesOsucVXmCWjMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/achats/commandes.vue")
  },
  {
    name: "workspace-gesfi-achats-factures",
    path: "factures",
    meta: facturest3A0hRZuUDMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/achats/factures.vue")
  },
  {
    name: "workspace-gesfi-achats",
    path: "",
    meta: indexIYoiwNWZYiMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/achats/index.vue")
  },
  {
    name: "workspace-gesfi-achats-reglements",
    path: "reglements",
    meta: reglementsJ9ciXj7VsbMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/achats/reglements.vue")
  }
]
  },
  {
    name: dashboardsaqW0zROQ6EMeta?.name,
    path: "dashboards",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/dashboards.vue"),
    children: [
  {
    name: "workspace-gesfi-dashboards-facturation",
    path: "facturation",
    meta: facturation3rkAWMoOWYMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/dashboards/facturation.vue")
  },
  {
    name: "workspace-gesfi-dashboards",
    path: "",
    meta: indexYdHFTWz0kZMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/dashboards/index.vue")
  },
  {
    name: "workspace-gesfi-dashboards-ventes",
    path: "ventes",
    meta: ventesorSm34X6IlMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/dashboards/ventes.vue")
  }
]
  },
  {
    name: "workspace-gesfi-exports",
    path: "exports",
    meta: exportsMzcDM1G7FeMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/exports.vue")
  },
  {
    name: "workspace-gesfi",
    path: "",
    meta: indexlJbHF19hoyMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/index.vue")
  },
  {
    name: ventesJW1mSpcbjoMeta?.name,
    path: "ventes",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/ventes.vue"),
    children: [
  {
    name: "workspace-gesfi-ventes",
    path: "",
    meta: indexxSLqUiuAvYMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/ventes/index.vue")
  },
  {
    name: "workspace-gesfi-ventes-suivifactu",
    path: "suivifactu",
    meta: suivifactufb6xfEc35EMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/gesfi/ventes/suivifactu.vue")
  }
]
  }
]
  },
  {
    name: "workspace",
    path: "",
    meta: indexHBBtryseX5Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/index.vue")
  },
  {
    name: "workspace-metres",
    path: "metres",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/metres.vue")
  },
  {
    name: parametresY9qz06ulSoMeta?.name,
    path: "parametres",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres.vue"),
    children: [
  {
    name: "workspace-parametres-diffusion",
    path: "diffusion",
    meta: diffusionsEAtXwjut0Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/diffusion.vue"),
    children: [
  {
    name: "workspace-parametres-diffusion-annonces",
    path: "annonces",
    meta: annonces8OYE5Y22axMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/diffusion/annonces.vue")
  },
  {
    name: "workspace-parametres-diffusion-ubiflow",
    path: "ubiflow",
    meta: ubiflowlRtttXZHdAMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/diffusion/ubiflow.vue")
  }
]
  },
  {
    name: documents3INYxLQg7tMeta?.name,
    path: "documents",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/documents.vue"),
    children: [
  {
    name: "workspace-parametres-documents",
    path: "",
    meta: indexChLB7qeoTfMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/documents/index.vue")
  },
  {
    name: "workspace-parametres-documents-types",
    path: "types",
    meta: typeskX8OdhOVR3Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/documents/types.vue")
  }
]
  },
  {
    name: editionsIJ7I43rDeSMeta?.name,
    path: "editions",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/editions.vue"),
    children: [
  {
    name: "workspace-parametres-editions-general",
    path: "general",
    meta: generalZMvgHsU0VzMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/editions/general.vue")
  },
  {
    name: "workspace-parametres-editions",
    path: "",
    meta: index3jctKFuC1aMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/editions/index.vue")
  },
  {
    name: "workspace-parametres-editions-notices",
    path: "notices",
    meta: noticesPwu1x04c26Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/editions/notices.vue")
  },
  {
    name: "workspace-parametres-editions-ppcst",
    path: "ppcst",
    meta: ppcstAH1hNzSZ5YMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/editions/ppcst.vue")
  }
]
  },
  {
    name: financiersjDJSfr0ArEMeta?.name,
    path: "financiers",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/financiers.vue"),
    children: [
  {
    name: "workspace-parametres-financiers-affectations",
    path: "affectations",
    meta: affectations4ysgIZt2owMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/financiers/affectations.vue")
  },
  {
    name: "workspace-parametres-financiers-banque",
    path: "banque",
    meta: banqueYiMhzK0tDJMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/financiers/banque.vue")
  },
  {
    name: "workspace-parametres-financiers-facturation",
    path: "facturation",
    meta: facturationr0UCLS57hWMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/financiers/facturation.vue")
  },
  {
    name: "workspace-parametres-financiers",
    path: "",
    meta: indexpbnEcqFP0pMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/financiers/index.vue")
  },
  {
    name: "workspace-parametres-financiers-validations",
    path: "validations",
    meta: validationsL73EWZdvYeMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/financiers/validations.vue")
  }
]
  },
  {
    name: "workspace-parametres-garants",
    path: "garants",
    meta: garantsz37xcQzSzxMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/garants.vue")
  },
  {
    name: "workspace-parametres",
    path: "",
    meta: index83bEx22gl7Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/index.vue")
  },
  {
    name: "workspace-parametres-marque",
    path: "marque",
    meta: marqueDSrLoQ2tBPMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/marque.vue")
  },
  {
    name: "workspace-parametres-secteurs",
    path: "secteurs",
    meta: secteursCR9drwd6YzMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/secteurs.vue")
  },
  {
    name: "workspace-parametres-utilisateurs",
    path: "utilisateurs",
    meta: utilisateurssR84EBrpYcMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/utilisateurs.vue")
  },
  {
    name: "workspace-parametres-vente",
    path: "vente",
    meta: ventePWsZL5fhWKMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/parametres/vente.vue")
  }
]
  },
  {
    name: projetsBfKIsTdeb8Meta?.name,
    path: "projets",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets.vue"),
    children: [
  {
    name: _91id_93subc7v0n4XMeta?.name,
    path: ":id()",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id].vue"),
    children: [
  {
    name: avpQ3AKBemg4nMeta?.name,
    path: "avp",
    meta: avpQ3AKBemg4nMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/avp.vue"),
    children: [
  {
    name: "workspace-projets-id-avp",
    path: "",
    meta: indexi6lTsLMxs3Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/avp/index.vue")
  },
  {
    name: "workspace-projets-id-avp-metre-mid",
    path: "metre/:mid()",
    meta: _91mid_93ibLayAGHrGMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/avp/metre/[mid].vue"),
    children: [
  {
    name: "workspace-projets-id-avp-metre-mid-lignes",
    path: "lignes",
    meta: lignesgkehLgUXdAMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/avp/metre/[mid]/lignes.vue")
  },
  {
    name: "workspace-projets-id-avp-metre-mid-options",
    path: "options",
    meta: optionsViHjV6eoKRMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/avp/metre/[mid]/options.vue")
  }
]
  }
]
  },
  {
    name: contrateOMbLZ3w2QMeta?.name,
    path: "contrat",
    meta: contrateOMbLZ3w2QMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/contrat.vue"),
    children: [
  {
    name: "workspace-projets-id-contrat-analytics",
    path: "analytics",
    meta: analyticsYp9qdR9BhXMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/contrat/analytics.vue")
  },
  {
    name: "workspace-projets-id-contrat-avenants",
    path: "avenants",
    meta: avenantsHU6GtehhuZMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/contrat/avenants.vue")
  },
  {
    name: "workspace-projets-id-contrat-facturation",
    path: "facturation",
    meta: facturationWF2TBNAKYgMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/contrat/facturation.vue")
  },
  {
    name: "workspace-projets-id-contrat",
    path: "",
    meta: indexVrQuxMTbWIMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/contrat/index.vue")
  }
]
  },
  {
    name: "workspace-projets-id",
    path: "",
    meta: indexIs2frD66hXMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/index.vue")
  },
  {
    name: "workspace-projets-id-pp",
    path: "pp",
    meta: ppIQeObynHeRMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp.vue"),
    children: [
  {
    name: "workspace-projets-id-pp-ci",
    path: "ci",
    meta: cieIuwhuAS5AMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/ci.vue")
  },
  {
    name: "workspace-projets-id-pp-epe",
    path: "epe",
    meta: epe4unimOJoLsMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/epe.vue")
  },
  {
    name: "workspace-projets-id-pp-financement",
    path: "financement",
    meta: financementysDOWDnxPYMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/financement.vue")
  },
  {
    name: "workspace-projets-id-pp-garanties",
    path: "garanties",
    meta: garantiesX6xHHmGbIrMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/garanties.vue")
  },
  {
    name: "workspace-projets-id-pp-pc",
    path: "pc",
    meta: pcZkEG69MPrZMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/pc.vue")
  },
  {
    name: "workspace-projets-id-pp-prepaschantiers",
    path: "prepaschantiers",
    meta: prepaschantiersx8vqEa4QFhMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/prepaschantiers.vue")
  },
  {
    name: "workspace-projets-id-pp-terrain",
    path: "terrain",
    meta: terrainZfYy3UXDpZMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/pp/terrain.vue")
  }
]
  },
  {
    name: travauxdr4pp5JdbAMeta?.name,
    path: "travaux",
    meta: travauxdr4pp5JdbAMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux.vue"),
    children: [
  {
    name: cmdfT6e2R2UCOMeta?.name,
    path: "cmd",
    meta: cmdfT6e2R2UCOMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/cmd.vue"),
    children: [
  {
    name: _91cid_93QHObVpk2jgMeta?.name,
    path: ":cid()",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/cmd/[cid].vue"),
    children: [
  {
    name: "workspace-projets-id-travaux-cmd-cid",
    path: "",
    meta: indexcpJCKyVXneMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/cmd/[cid]/index.vue")
  }
]
  },
  {
    name: "workspace-projets-id-travaux-cmd",
    path: "",
    meta: indexNfBgoKpEAnMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/cmd/index.vue")
  }
]
  },
  {
    name: "workspace-projets-id-travaux-documents",
    path: "documents",
    meta: documentsF6sm9Bn1XaMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/documents.vue")
  },
  {
    name: "workspace-projets-id-travaux",
    path: "",
    meta: indexxmqRz3vMd5Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/index.vue")
  },
  {
    name: "workspace-projets-id-travaux-planification",
    path: "planification",
    meta: planification4yjSZVlxH1Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/[id]/travaux/planification.vue")
  }
]
  }
]
  },
  {
    name: "workspace-projets",
    path: "",
    meta: indexDLI0ERxKwAMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/projets/index.vue")
  }
]
  },
  {
    name: travauxeEL6touxVfMeta?.name,
    path: "travaux",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux.vue"),
    children: [
  {
    name: "workspace-travaux",
    path: "",
    meta: indexEj22u0Z1xuMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/index.vue")
  },
  {
    name: mtS4A8pwuvaWMeta?.name,
    path: "mt",
    meta: mtS4A8pwuvaWMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/mt.vue"),
    children: [
  {
    name: _91id_93dUNhgKkh1zMeta?.name,
    path: ":id()",
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/mt/[id].vue"),
    children: [
  {
    name: "workspace-travaux-mt-id-commandes",
    path: "commandes",
    meta: commandesgmgcTnNgiTMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/mt/[id]/commandes.vue")
  },
  {
    name: "workspace-travaux-mt-id",
    path: "",
    meta: index7oRrsnjU1aMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/mt/[id]/index.vue")
  },
  {
    name: "workspace-travaux-mt-id-marches",
    path: "marches",
    meta: marches15YdozUEEuMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/mt/[id]/marches.vue")
  }
]
  },
  {
    name: "workspace-travaux-mt",
    path: "",
    meta: indexN6ykuvwekbMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/mt/index.vue")
  }
]
  },
  {
    name: "workspace-travaux-suivi",
    path: "suivi",
    meta: suiviWsrjySnXh7Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/[workspace]/travaux/suivi.vue")
  }
]
  }
]
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordHLJDhTRpVzMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index8rmyUL6wd0Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginvtD6lzSst8Meta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/login.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordxjuZvla6GJMeta || {},
    component: () => import("/home/cleavr/app.briik.fr/releases/20241213095323233/pages/reset-password.vue")
  }
]